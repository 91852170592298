import Vue from 'vue'
import VueRouter from 'vue-router'

import admin from '@/views/admin/admin'
import user from '@/views/user/user'
import order from '@/views/order/order'
import reimbur from '@/views/reimbursement/reimbur'
import complaint from '@/views/complaint/complaint'
import complaints from '@/views/complaint/complaints'
import comsuccess from '@/views/complaint/comsuccess'

Vue.use(VueRouter)
// permissions 权限 0 用户,1 管理员 2,店员 3 核销员,4 保卡员
const routes = [
  {
    path: '/order',
    component: order,
    meta: {permissions:[1,4]}
  },
  {
    path: '/reimbur',
    component: reimbur,
    meta: {permissions:[1,3]}
  },
  {
    path: '*',
    redirect: '/user', //重定向首页
    meta: {}
  },
  {
    path: '/admin',
    component: admin,
    meta: { permissions: [1] }
  },
  {
    path: '/user',
    redirect: '/user/index',
    component: user,
    children: [
      { path: 'index', component: resolve => require(['@/views/user/index'], resolve)},
      { path: 'notPaid', name: 'NotPaid', component: resolve => require(['@/views/user/notPaid'], resolve), meta: {} },
      { path: 'transactionVoucher', name: 'TransactionVoucher', component: resolve => require(['@/views/user/transactionVoucher'], resolve), meta: { title: '交易凭证'} },
      { path: 'myTeams', name: 'MyTeams', component: resolve => require(['@/views/user/myTeams'], resolve), meta: {} },
      { path: 'commissionDetails', name: 'CommissionDetails', component: resolve => require(['@/views/user/commissionDetails'], resolve), meta: { title: '佣金明细'} }
    ],
    meta: { title: '首页' }
  },
  {
    path: '/complaint',
    component: complaint,
    meta: {}
  },
  {
    path: '/complaints',
    component: complaints,
    meta: {}
  },
  {
    path: '/comsuccess',
    component: comsuccess,
    meta: {}
  }
]

const router = new VueRouter({
  routes,
  mode: 'history' //未设置是hash
})

export default router
