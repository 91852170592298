<template>
  <div>
    <!-- 投诉 -->
    <div class="complain" @click="click">
      <div class="label">
        投诉
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Complain",
  methods: {
    // 跳转投诉页
    click() {
      this.$router.push('/complaint') 
    },
  },
};
</script>
<style lang="scss" scoped>
.complain {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  position: fixed;
  right: 40px;
  top: 228px;
  z-index: 12;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
  }
}
</style>