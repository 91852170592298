<template>
    <div class="container">
        <!-- 主体部分 -->
        <div class="main">
            <div class="main_icon">
                <van-icon name="success" />
            </div>
            <div class="main_text">投诉已经提交</div>
            <div class="main_texts">微信团队会尽快核实，并通过"微信团队"通知你审核结果、感谢你的支持</div>

            <div class="btn">
                <van-button type="primary" @click="goback">关闭</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast, Empty as vanEmpty, Button as vanButton, Icon as vanIcon, Tab as vanTab, Tabs as vanTabs, NavBar as vanNavBar } from 'vant'
export default {
    components: { vanEmpty, vanButton, vanIcon, vanTab, vanTabs, vanNavBar },
    data() {
        return {

        }
    },
    methods: {
        goback() {
            this.$router.go(-1)
        }
    },
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    font-size: 32px;
}

.main {
    width: 100%;
    height: 100vh;
    padding-top: .3rem;
    background: #F7F9FA;
    text-align: center;

    .main_icon {
        margin: .56rem auto .4rem;
        width: 166px;
        height: 166px;
        opacity: 1;
        text-align: center;
        line-height: 220px;
        background: #07C160;
        color: #F7F9FA;
        border-radius: 50%;

        .van-icon {
            font-size: 90px;
        }
    }

    .main_text {
        font-weight: bold;
        margin: 40px 16px 16px 16px;
        color: #323233;
    }

    .main_texts {
        width: 620px;
        margin: 16px auto;
        color: #646566;
        font-size: 24px;
        letter-spacing: 2px;
    }

    .btn {
        width: 710px;
        height: 80px;
        background: #07C160;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin: 80px auto 0;

        .van-button {
            width: 100%;
            height: 100%;
        }
    }

}
</style>