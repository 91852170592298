import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '',
    activityName: '',
    token: getToken()
  },
  getters: {},
  mutations: {
    setActivityName(state, value) {
      state.activityName = value
    },
    SET_TITLE: (state, value) => {
      state.activityName = value
    },
    ET_TOKEN: (state, token) => {
      state.token = token
    }
  }
})
