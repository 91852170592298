import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入vant组件样式
import 'vant/lib/index.css'
// 引入移动端适配
import 'amfe-flexible'
import { Notify } from 'vant'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'

// 挂载跳转push方法
Vue.prototype.$pushBack = function (parameter) {
  this.$router.push(parameter)
}

// 挂载Cookies
Vue.prototype.$Cookies = Cookies

// 时间戳
import moment from 'moment'
// 定义全局时间戳过滤器
Vue.filter('formatDate', function (value, format = 'MM/DD HH:mm:ss') {
  return moment(value * 1000).format(format)
})
const whiteList = ['/user', '/user/index', '/complaint', '/complaints', '/comsuccess']
// 全局 before 钩子，可以用来判断是否登录等前置操作。
router.beforeEach((to, from, next) => {
  if (getToken()) {
    // 如果token不为null，并且有访问权限 就放行，如果为空 就不放行
    let permissions2 = Cookies.get('permissions') || [0]
    let permissions = to.meta['permissions'] || []
    let is_permission = false
    for (let i = 0; i < permissions.length; i++) {
      if (permissions2.includes(permissions[i])) {
        is_permission = true
      }
    }
    if (is_permission || whiteList.indexOf(to.path) !== -1 || permissions.length == 0) {
      next()
    } else {
      Notify({
        type: 'danger',
        message: '无访问权限'
      })
    }
  } else {
    let query = to['query'] || {}
    to['query']['order_id'] && Cookies.set('order_id', to['query']['order_id'])
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 判断用户首页有无参数，没有添加，直接进入
      if (to.path == '/user/index' && !query['activity_id']) {
        next({ path: '/user/index', query: { activity_id: Cookies.get('activity_id') || query['activity_id'], parent_id: Cookies.get('parent_id') || query['parent_id'] } }) // 重定向到首页
      } else {
        // 在免登录白名单，直接进入
        next()
      }
      next()
    } else {
      // 判断是否是订单列表页，设置重定向
      if (to.path == '/reimbur') {
        Cookies.set('redirect', JSON.stringify({ path: to.path, query: { activity_id: Cookies.get('activity_id') || query['activity_id'], parent_id: Cookies.get('parent_id') || query['parent_id'], order_id: query['order_id'] } }))
      }
      next({ path: '/user/index', query: { activity_id: Cookies.get('activity_id') || query['activity_id'], parent_id: Cookies.get('parent_id') || query['parent_id'] } }) // 重定向到首页
    }
  }
})
import { NavBar } from 'vant'
Vue.use(NavBar)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
