<template>
  <div class="container">
    <van-overlay :show="loading">
      <div class="wrapper">
        <van-loading size="24px" vertical type="spinner">加载中...</van-loading>
      </div>
    </van-overlay>
    <!-- 主体部分 -->
    <div class="main" v-if="orderInfos['order_no']">
      <ul>
        <li>
          <div class="list_info">
            <div class="list_info_left">
              <van-icon name="records" color="#F8605B" />报名信息
            </div>
            <div class="list_info_rigth">已支付</div>
          </div>
          <div class="list_details">
            <div class="list_details_left">姓名</div>
            <div class="list_details_rigth">
              {{ orderInfos["user"]["name"] + "【团长】" }}
            </div>
          </div>
          <div class="list_details">
            <div class="list_details_left">电话</div>
            <div class="list_details_rigth">{{ orderInfos["user"]["mobile"] }}</div>
          </div>
          <div class="list_details">
            <div class="list_details_left">支付金额</div>
            <div class="list_details_rigth">￥{{ orderInfos["total_pay_price"] }}元</div>
          </div>
          <div class="list_details">
            <div class="list_details_left">订单编号</div>
            <div class="list_details_rigth">{{ orderInfos["order_no"] }}</div>
          </div>
          <div class="list_details">
            <div class="list_details_left">交易时间</div>
            <div class="list_details_rigth">
              {{ orderInfos["created_at"] | formatDate("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <van-button
        round
        type="danger"
        @click="getAdminHan"
        v-if="!btnDisabled && orderInfos['order_no']"
        >确认核销
      </van-button>
      <van-button round type="danger" :disabled="btnDisabled" v-if="btnDisabled"
        >已核销</van-button
      >
    </div>
  </div>
</template>

<script>
import {
  Button as vanButton,
  Overlay as vanOverlay,
  Loading as vanLoading,
  Icon as vanIcon,
} from "vant";
import { adminHan, adminInfo } from "@/api/order";
export default {
  components: { vanButton, vanIcon, vanOverlay, vanLoading },
  data() {
    return {
      btnDisabled: false,
      loading: true,
      orderInfos: {},
      order_id: "",
    };
  },
  created() {
    this.$route.query.order_id ||
      (this.order_id &&
        this.$Cookies.set("order_id", this.$route.query.order_id || this.order_id));
    this.order_id = this.$route.query.order_id || this.order_id;
    this.$Cookies.remove("order_id");
    this.orderInfo();
  },
  methods: {
    confirm() {
      this.btnDisabled = true;
      Toast.success("核销成功");
    },
    getAdminHan() {
      this.$toast.loading({
        message: "核销中...",
        forbidClick: true,
        duration: 0,
      });
      adminHan({ order_id: this.order_id || this.$Cookies.get("order_id") })
        .then((res) => {
          this.$toast.clear();
          if (res.errcode == 0) {
            this.btnDisabled = true;
          } else {
            this.loading = false;
            if (res.msg == "订单已核销") {
              this.btnDisabled = true;
            }
          }
          this.loading = false;
        })
        .catch((res) => {
          this.$toast.clear();
          this.$toast.fail("adminHan：" + res.message);
        });
    },
    // 订单信息
    orderInfo() {
      adminInfo({ order_id: this.order_id || this.$Cookies.get("order_id") })
        .then((res) => {
          this.$toast.clear();
          if (res.errcode == 0) {
            this.orderInfos = res.data;
          } else {
            this.$toast.fail("adminInfo" + res.msg);
          }
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
          this.$toast.clear();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  font-size: 32px;
}

.main {
  width: 100%;
  height: calc(100vh - 156px);
  padding-top: 10px;
  background: #f7f9fa;

  ul {
    li {
      margin: 36px;
      padding: 32px;
      background: #fff;
      border-radius: 0.2rem;

      .list_info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list_info_left {
          .van-icon {
            margin-right: 0.1rem;
            font-size: larger;
          }

          font-weight: bold;
          letter-spacing: 0.03rem;
        }

        .list_info_rigth {
          color: #07c160;
        }
      }

      .list_details {
        height: 108px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.02rem solid #f7f8fa;

        .list_details_left {
          color: #646566;
        }

        .list_details_rigth {
          color: #323233;
        }
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 156px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .van-button {
    width: 670px;
    height: 92px;
    background: #e93c3e;
    box-shadow: 0px 8px 16px 0px rgba(233, 60, 62, 0.4);
    border: 0;
    border-radius: 198px 198px 198px 198px;
  }

  button[disabled] {
    width: 670px;
    height: 92px;
    border-radius: 198px 198px 198px 198px;
    color: white !important;
    background: #c8c9cc !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04) !important;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
