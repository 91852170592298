<template>
  <div class="container">
    <!-- 主体部分 -->
    <div class="main">
      <!-- tab切换 -->
      <div class="main-top">
        <van-tabs v-model="activeName" @click="onClick">
          <van-tab title="订单列表" name="a"></van-tab>
          <van-tab title="访问用户" name="b"></van-tab>
        </van-tabs>
      </div>

      <!-- 订单统计 -->
      <div class="mian-other" v-show="listtype == true">
        <div class="other_top">
          <div class="total">总金额（元）</div>
          <div class="total-num">{{ this.toalsList.total_amount }}</div>
        </div>
        <div class="other_bottom">
          <div class="unpaid-num">
            <p>未支付订单数</p>
            <div class="nums">{{ this.toalsList.nopay_sum }}</div>
          </div>
          <div class="paid-num">
            <p>已支付订单数</p>
            <div class="nums">{{ this.toalsList.pay_sum }}</div>
          </div>
          <div class="paided-num">
            <p>已核销订单数</p>
            <div class="nums">{{ this.toalsList.handle_sum }}</div>
          </div>
        </div>
      </div>

      <div class="mian-others" v-show="listtype !== true">
        <div class="others_top">
          共 <span>{{ this.pagesNum.total_count }}</span> 个访问用户
        </div>
      </div>

      <!-- 全部 按钮 -->
      <div class="main-center" v-show="listtype == true">
        <van-button
          round
          size="mini"
          @click="addStyleData(0, 0)"
          :class="activeClass === 0 ? 'active' : ''"
        >
          全部
        </van-button>
        <van-button
          round
          size="mini"
          @click="addStyleData(1, 0)"
          :class="activeClass === 1 ? 'active' : ''"
        >
          已支付
        </van-button>
        <van-button
          round
          size="mini"
          @click="addStyleData(2, 0)"
          :class="activeClass === 2 ? 'active' : ''"
        >
          未支付
        </van-button>
        <van-button
          round
          size="mini"
          @click="addStyleData(3, 0)"
          :class="activeClass === 3 ? 'active' : ''"
        >
          核销成功
        </van-button>
      </div>

      <!-- 订单列表详情 -->
      <div class="main-bottom">
        <!-- 订单列表 -->
        <ul v-show="listtype == true">
          <!-- 下拉刷新 -->
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              error-text="请求失败，点击重新加载"
              offset="10"
              immediate-check
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <li v-for="(item, index) in ordList" :key="item.id">
                <div class="list_top">
                  <!-- 数字 -->
                  <div class="list_top_left">
                    <div class="number">{{ item.order_no }}</div>
                    <div class="timer">{{ item.created_at | formatDate }}</div>
                  </div>
                  <div class="list_top_right">
                    <p class="paid" :class="styleClass[item.status.status_id]">
                      {{ item.status.status_name }}
                    </p>
                  </div>
                </div>
                <div class="list_bottom">
                  <!-- 头像 -->
                  <div class="list_bottom_left">
                    <div class="image">
                      <img :src="item.user.headimgurl" alt="" />
                    </div>
                    <div class="username">
                      <div>{{ item.user.nickname }}</div>
                      <div>{{ item.user.mobile }}</div>
                    </div>
                  </div>
                  <!-- 价格 -->
                  <div class="list_bottom_right">￥{{ item.total_pay_price }}</div>
                </div>
              </li>
            </van-list>
          </van-pull-refresh>
        </ul>

        <!-- 数据为空 -->
        <van-empty
          v-if="
            (ordList.length == 0 && this.listtype) ||
            (userList.length == 0 && !this.listtype)
          "
          description=""
        />

        <!-- 访问用户 -->
        <ul v-show="listtype == false">
          <!-- 下拉刷新 -->
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              error-text="请求失败，点击重新加载"
              offset="10"
              immediate-check
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <li v-for="(item, index) in userList" :key="item.id">
                <div class="list_bottom">
                  <!-- 头像 -->
                  <div class="list_bottom_left">
                    <div class="image">
                      <img :src="item.user.headimgurl" alt="" />
                    </div>
                    <div class="username">
                      <div>{{ item.user.name }}</div>
                      <div>{{ item.user.mobile }}</div>
                    </div>
                  </div>
                  <!-- 价格 -->
                  <div class="list_bottom_right1">
                    <p>{{ item.created_at | formatDate }}</p>
                  </div>
                </div>
              </li>
            </van-list>
          </van-pull-refresh>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { adminAce, adminList, adminStaics } from "@/api/order";
import Cookies from "js-cookie";
import {
  Toast,
  Cell as vanCell,
  List as vanList,
  PullRefresh as vanPullRefresh,
  Empty as vanEmpty,
  Button as vanButton,
  Icon as vanIcon,
  Tab as vanTab,
  Tabs as vanTabs,
} from "vant";
export default {
  components: {
    vanEmpty,
    vanButton,
    vanIcon,
    vanTab,
    vanTabs,
    vanPullRefresh,
    vanList,
    vanCell,
  },
  data() {
    return {
      activeName: "a",
      listtype: true,
      index: 0,
      activeClass: 0, // 默认样式
      ordList: {}, // 订单列表
      userList: {}, // 用户
      toalsList: {}, // 统计
      pages: {},
      pagesNum: {},
      styleClass: {
        1: "paid",
        2: "unpaid",
        3: "success",
      },
      loading: false, // 滚动加载中
      finished: false, // 滚动加载完成
      isLoading: false, // 下拉强制刷新
      refreshing: false, // 是否在加载中
    };
  },
  created() {
    this.getAdminList();
    this.getAdminAce();
    this.getAdminStaics();
  },
  methods: {
    onClick(name, title) {
      this.listtype = !this.listtype;
    },
    addStyleData(index, type) {
      if (this.listtype) {
        this.activeClass = index;
        this.getAdminList();
      } else {
        this.activeClass = index;
        this.getAdminAce();
      }
    },
    // 订单列表
    async getAdminList() {
      let res = await adminList({
        activity_id: Cookies.get("activity_id"),
        // activity_id: 1,
        status: this.activeClass,
      });
      // console.log("list", res);

      if (res.errcode == 0) {
        this.ordList = res.data.list;
        this.pages = res.data.pagination;
      } else {
        Toast(res.msg);
      }
    },
    // 访问用户
    async getAdminAce() {
      let res = await adminAce({
        activity_id: Cookies.get("activity_id"),
        // activity_id: 1,
        status: this.activeClass,
      });
      // console.log("access", res);
      if (res.errcode == 0) {
        this.userList = res.data.list;
        this.pagesNum = res.data.pagination;
      } else {
        Toast(res.msg);
      }
    },
    // 订单统计
    async getAdminStaics() {
      let res = await adminStaics({
        activity_id: Cookies.get("activity_id"),
        // activity_id: 1,
      });
      // console.log(res, "sta");
      if (res.errcode == 0) {
        this.toalsList = res.data;
      } else {
        Toast(res.msg);
      }
    },
    // 上拉加载
    onLoad() {
      if (this.refreshing) {
        this.refreshing = false;
        this.pages.current_page + 1;
      }
      this.loading = false;

      if (this.pages.current_page == this.pages.per_page) {
        this.finished = true;
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
      this.getAdminList();
      this.ordList = [];
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
}

.main {
  width: 100%;
  height: 100vh;
  background: #f7f9fa;

  .main-top {
    height: 1.5rem;
    margin: 0.08rem 0 0 0;
    background: #f7f9fa;
  }

  .mian-others {
    line-height: 64px;
    height: 64px;

    .others_top {
      height: 44px;
      font-size: 28px;
      margin-left: 38px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #646566;

      span {
        font-weight: 550;
      }
    }
  }

  .mian-other {
    width: 690px;
    height: 254px;
    background: #e93c3e linear-gradient(134deg, #ff8266 0%, #fe1748 100%);
    border-radius: 8px 8px 8px 8px;
    margin: 0 auto;
    padding: 32px 0px 0px 32px;
    color: #ffffff;

    .other_top {
      .total {
        width: 184px;
        height: 34px;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        margin: 4px;
      }

      .total-num {
        width: 186px;
        height: 68px;
        font-size: 48px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 56px;
      }
    }

    .other_bottom {
      width: 646px;
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      p {
        height: 34px;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }

      .nums {
        height: 50px;
        font-size: 36px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 50px;
      }
    }
  }

  .main-center {
    height: 60px;
    padding: 0 0.2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 32px;

    .van-button.active {
      background: #e93c3e;
      color: #f7f9fa;
    }

    .van-button {
      background: #ebedf0;
      color: #969799;
      padding: 28px 30px;
      font-size: 28px;
    }
  }

  .main-bottom {
    padding: 0 0.2rem;

    ul {
      margin-top: 20px;
      li {
        margin: 8px 0;
        background: #ffffff;
        padding: 0.32rem;

        .list_top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.02rem solid #f2f3f5;
          padding-bottom: 0.16rem;

          .list_top_left {
            .number {
              font-size: 28px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #323233;
            }

            .timer {
              font-size: 24px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #969799;
            }
          }

          .list_top_right {
            .paid {
              color: #07c160;
              font-size: 28px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
            }

            .unpaid {
              color: #ff3c4a;
              font-size: 28px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
            }

            .success {
              color: #d3d4d6;
              font-size: 28px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
            }
          }
        }

        .list_bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          .list_bottom_left {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .image {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              background: #969799;
              margin-right: 32px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

            .username {
              font-size: 32px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #323233;
            }
          }

          .list_bottom_right {
            font-size: 28px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323233;
          }
          .list_bottom_right1 {
            font-size: 24px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #969799;
          }
        }
      }
    }
  }
}
</style>
