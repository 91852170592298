<template>
    <div class="container">

        <!-- 主体部分 -->
        <div class="main">
            <div class="main_title">你可以 :</div>
            <div class="main_list">
                <ul>
                    <li @click="next">
                        <div class="text">提交给微信团队审核</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 底部 -->
        <div class="footer">投诉须知</div>
    </div>
</template>

<script>
import { Toast, Empty as vanEmpty, Button as vanButton, Icon as vanIcon, Tab as vanTab, Tabs as vanTabs, NavBar as vanNavBar } from 'vant'
export default {
    components: { vanEmpty, vanButton, vanIcon, vanTab, vanTabs, vanNavBar },
    data() {
        return {

        }
    },
    methods: {
        next() {
            this.$router.push('/comsuccess')
        }
    },
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    background: #F7F9FA;
    font-size: 32px;
}

.main {
    width: 100%;
    height: calc(100vh - 120px);


    .main_title {
        padding: 12px 20px;
        height: 1rem;
        line-height: 1rem;
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #646566;
    }

    .main_list {

        ul {
            background: #fff;

            li {
                padding: 0 .32rem;
                display: flex;
                height: 104px;
                justify-content: space-between;
                align-items: center;

                .text {
                    color: #323233;
                }

                .icon {
                    color: #C8C9CC;
                }
            }
        }
    }

}

.footer {
    color: #61749B;
    width: 100%;
    height: 120px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>