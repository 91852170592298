<template>
    <div class="container">
        <!-- 头部标题 -->
        <div class="head">
            <van-nav-bar title="活动标题" left-text="x" right-text="···" @click-left="onClickLeft"
                @click-right="onClickRight" />
        </div>

        <!-- 主体部分 -->
        <div class="main">
            <!-- 投诉 -->
            <Complain></Complain>

            <!-- 分享海报 -->
            <div class="share">
                <div class="share_text">分享海报</div>
                <div class="share_icon">
                    <van-icon name="phone-o" />
                </div>
            </div>

            <div class="order">
                <div class="btn">
                    <div class="text">管理中心</div>
                    <div class="icon">
                        <van-icon name="bar-chart-o" />
                    </div>
                </div>

                <div class="btn">
                    <div class="text">我的订单</div>
                    <div class="icon">
                        <van-icon name="balance-list-o" />
                    </div>
                </div>
            </div>


        </div>

        <!-- 底部 -->
        <div class="footer">
            <van-button type="warning">
                <p>￥299.00</p>
                <p>我要做队长</p>
            </van-button>
            <van-button type="danger">
                <p>￥299.00</p>
                <p>加入小队</p>
            </van-button>
        </div>
    </div>
</template>

<script>
import { Toast as vanToast, Button as vanButton, Icon as vanIcon, Tab as vanTab, Tabs as vanTabs } from 'vant';
import Complain from '@/components/complain.vue';

export default {
    components: { Complain,vanToast, vanButton, vanIcon, vanTab, vanTabs },
    data() {
        return {

        };
    },
    mounted() {
        
    },
    methods: {
        onClickLeft() {
            this.$router.back()
            Toast('返回');
        },
        onClickRight() {
            Toast('按钮');
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
}

.head {
    width: 100%;
    height: 46px;
    font-size: .2rem;
}

.main {
    width: 100%;
    height: 88%;
    background: #9C3F3F;

    .share {
        width: 1.1rem;
        height: 2.4rem;
        text-align: center;
        position: fixed;
        top: 58%;
        right: 2%;
        border: 0;
        color: #fff;
        font-size: .19rem;

        .share_text {
            width: 1rem;
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #fff;
            border: .04rem solid #FFFFFF;
            margin-bottom: .2rem;
            background: #FD4140;
            font-size: .34rem;
        }

        .share_icon {
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            border: .04rem solid #FFFFFF;
            background: #FD4140;
            font-size: .6rem;
        }
    }

    .order {
        width: 90px;
        height: 98px;
        position: fixed;
        left: 0;
        bottom: 80px;


        .btn {
            width: 86px;
            height: 40px;
            color: #fff;
            display: flex;
            background: rgba($color: #000000, $alpha: 0.3);
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            margin: 8px 0;

            .text {
                width: 28px;
                height: 36px;
                font-size: 14px;
                margin: 0 8px;
            }

            .icon {
                width: 34px;
                height: 34px;
                margin: 3px;
                border-radius: 50%;
                background: #FD4140;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;

    .van-button {
        width: 50%;
        border-radius: 0;
    }
}
</style>