import request from '../utils/request'

// 管理 订单详情 /v1/admin/order-info  ok
export function adminInfo(data) {
  return request({
    url: '/v1/admin/order-info',
    method: 'post',
    data: data
  })
}

// 管理 订单列表 /v1/admin/order-list  ok
export function adminList(data) {
  return request({
    url: '/v1/admin/order-list',
    method: 'post',
    data: data
  })
}

// 管理 访问用户  /v1/admin/access-list
export function adminAce(data) {
  return request({
    url: '/v1/admin/access-list',
    method: 'post',
    data: data
  })
}

// 管理 核销 /v1/admin/handle ok
export function adminHan(data) {
  return request({
    url: '/v1/admin/handle',
    method: 'post',
    data: data
  })
}

// 管理 订单统计 /v1/admin/order-statistics
export function adminStaics(data) {
  return request({
    url: '/v1/admin/order-statistics',
    method: 'post',
    data: data
  })
}