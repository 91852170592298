<template>
    <div class="container">
        <!-- 主体部分 -->
        <div class="main">
            <div class="main_title">请选择投诉该网页的原因 :</div>
            <div class="main_list">
                <ul>
                    <li @click="next">
                        <div class="text">网页包含欺诈信息(如:假红包)</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                    <li @click="next">
                        <div class="text">网页包含色情信息</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                    <li @click="next">
                        <div class="text">网页包含暴力恐怖信息</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                    <li @click="next">
                        <div class="text">网页包含政治敏感信息</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                    <li @click="next">
                        <div class="text">网页在收集个人隐私信息(如:钓鱼链接)</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                    <li @click="next">
                        <div class="text">网页包含诱导分享/关注性质的内容</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                    <li @click="next">
                        <div class="text">网页可能包含谣言信息</div>
                        <div class="icon">
                            <van-icon name="arrow" />
                        </div>
                    </li>
                </ul>
            </div>

            <div class="main_foot">遇到网页被劫持怎么办</div>
        </div>

        <!-- 底部 -->
        <div class="footer">投诉须知</div>
    </div>
</template>

<script>
import { Toast, Empty as vanEmpty, Button as vanButton, Icon as vanIcon, Tab as vanTab, Tabs as vanTabs, NavBar as VanNavBar } from 'vant'
export default {
    components: { VanNavBar, vanEmpty, vanButton, vanIcon, vanTab, vanTabs },
    data() {
        return {

        }
    },
    methods: {
        next() {
            this.$router.push('/complaints')
        }
    },
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    font-size: 32px;
    background: #F7F9FA;
}


.main {
    width: 100%;
    height: calc(100vh - 120px);


    .main_title {
        width: 336px;
        height: 40px;
        font-size: 28px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #646566;
        line-height: 40px;
        padding: 32px 20px;
    }

    .main_list {

        ul {
            background: #fff;

            li {
                padding: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 2px solid #F7F8FA;

                .text {
                    color: #323233;
                    font-weight: 540;
                }

                .icon {
                    color: #C8C9CC;
                }
            }
        }
    }

    .main_foot {
        padding: 0 32px;
        margin: 32px 0;
        color: #61749B;
    }
}

.footer {
    color: #61749B;
    width: 100%;
    height: 120px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>