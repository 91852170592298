<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "User",
  computed: {
    ...mapState(["activityName"]),
  },
};
</script>
<style lang="scss" scoped></style>
